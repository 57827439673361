document.addEventListener('turbolinks:load', (e) => {
    ga('send', { hitType: 'pageview', page: e.data.url });

    const mainMenuButtonToggler = document.getElementById('main_menu_button_toggler')

    if(mainMenuButtonToggler) {
        const mainMenu = document.getElementsByClassName('main-navigation-links')[0]

        if(mainMenu) {
            mainMenuButtonToggler.addEventListener('click', (e) => {
                if(mainMenu.classList.contains('main-menu-opened')) {
                    mainMenu.classList.remove('main-menu-opened')
                }
                else {
                    mainMenu.classList.add('main-menu-opened')
                }
                e.stopPropagation()
            })

            mainMenu.addEventListener('click', (e) => {
                if(mainMenu.classList.contains('main-menu-opened')) {
                    mainMenu.classList.remove('main-menu-opened')
                }

                e.stopPropagation()
            })
        }
    }
    if(document.getElementById('portfolio')) {
        const items = document.getElementsByClassName('thumbnail')

        if(items) {
            const itemsArray = Array.from(items)
            const overlay = document.getElementById('app_overlay')

            overlay.addEventListener('click', () => {
                if(overlay.classList.contains('show')) {
                    overlay.classList.remove('show')
                    overlay.firstChild.remove()
                }
            })

            itemsArray.forEach(i => {
                i.addEventListener('click', (e) => {
                    overlay.classList.add('show')

                    const child = new Image()

                    if(window.innerWidth < 992) {
                        child.src = e.target.src
                    }
                    else {
                        child.src = e.target.attributes.item('data-url').value
                    }

                    overlay.appendChild(child)
                })
            })
        }
    }

    const serviceElements = document.querySelectorAll('.services .service')
    const observedElement = document.querySelector('.services')

    if(serviceElements && observedElement) {
        serviceElements.forEach(e => e.classList.remove('service-transition'))

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                serviceElements.forEach(e => e.classList.add('service-transition'))

                return;
              }
            });
        });

        observer.observe(observedElement)
    }

    const heroActionButton = document.querySelector('.hero--action-button')

    if(heroActionButton) {
        const waButton = document.querySelector('.floating-button')
        waButton.classList.remove('wp-transition')

        const wpObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if(!entry.isIntersecting) {
                    if(!waButton.classList.contains('wp-transition')) {
                        waButton.classList.add('wp-transition')
                    }
                }
            })
        })

        wpObserver.observe(heroActionButton)
    }
})


